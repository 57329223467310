
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

function PostTemplate({ location, data }) {

  const post = data.markdownRemark
  const { previous, next } = data

  // TODO: add article info: https://developers.google.com/search/docs/advanced/structured-data/article

  // TODO: can some posts use this? https://developers.google.com/search/docs/advanced/structured-data/qapage

  // TODO: can some posts use this? https://developers.google.com/search/docs/advanced/structured-data/practice-problems

  // TODO: can we use this in some posts: https://developers.google.com/search/docs/advanced/structured-data/how-to

  return (
    <Layout location={location}>

      <Seo
        title={post.frontmatter.title}
        metaDescription={post.frontmatter.description || post.excerpt} 
      />

      <article className="prose prose-quoteless prose-code:before:content-none prose-code:after:content-none lg:prose-xl">
        <header>
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <hr />

        <nav>
          <div className="pb-32 row">
            <div className="w-full mx-auto">
              <div className="grid-cols-4 gap-5 px-16 mx-auto sm:grid">
                <div className="col-start-1 col-end-3 my-2">
                  {next && (
                    <Link to={next.frontmatter.slug || next.fields.slug} rel="prev">
                      <div className="h-full p-6 bg-white">
                        <h3 className="inline-flex mb-3 text-xl font-semibold">
                          <svg className="mr-2" width="24" height="30" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.02698 11.9929L5.26242 16.2426L6.67902 14.8308L4.85766 13.0033L22.9731 13.0012L22.9728 11.0012L4.85309 11.0033L6.6886 9.17398L5.27677 7.75739L1.02698 11.9929Z" fill="currentColor" />
                          </svg>
                          Next
                        </h3>
                        <p className="text-base">← {next.frontmatter.title}</p>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="col-span-2 col-end-5 my-2">
                  {previous && (
                    <Link to={previous.frontmatter.slug || previous.fields.slug} rel="prev">
                      <div className="h-full p-6 text-right bg-white">
                        <h3 className="inline-flex mb-3 text-xl font-semibold ">
                          Prev
                          <svg className="ml-2" width="24" height="30" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z" fill="currentColor" />
                          </svg>
                        </h3>
                        <p className="text-base">{previous.frontmatter.title} →</p>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>

      </article>

    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query PostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        slug        
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        slug
      }
    }
  }
`
